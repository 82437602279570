<template>
  <div>
    <!-- STEP ONE-->
    <div v-if="step === 1">
      <div class="pt-lg-0 pt-5">
        <p class="text-muted font-weight-bold font-size-h4">
          Encontramos em nossos bancos de dados que você é cliente dos seguintes
          laboratórios. Escolha quais quer vincular ao OPTICert?
        </p>
      </div>
      <div class="form-group" v-if="laboratories.length > 0">
        <div v-for="(lab, k) in laboratories" :key="k">
          <label
            ><input
              type="checkbox"
              :name="lab.id"
              v-model="laboratory"
              :value="lab.id"
            />
            {{ lab.name }}</label
          >
        </div>
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          class="
            btn btn-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
            mr-4
          "
          style="width: 150px"
          @click="step = 2"
        >
          CONTINUAR
        </button>
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="
            btn btn-light-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
          "
          @click="$router.push('/dashboard')"
        >
          CONFIGURAR DEPOIS
        </button>
      </div>
    </div>

    <!-- STEP TWO-->
    <div v-if="step === 2">
      <div class="pt-lg-0 pt-5">
        <p class="text-muted font-weight-bold font-size-h4">
          <strong class="text-dark">Complete seu perfil</strong><br />
          Envie o logotipo da sua ótica. Tamanho máximo 300px x 100px no formato
          PNG. Ele será aplicado em fundo branco.
        </p>
      </div>
      <div class="form-group">
        <image-uploader
          :debug="1"
          :maxWidth="512"
          :quality="0.7"
          :autoRotate="true"
          outputFormat="verbose"
          :preview="false"
          :className="['fileinput', { 'fileinput--loaded': hasImage }]"
          capture="environment"
          accept="video/*,image/*"
          doNotResize="['gif', 'svg']"
          @input="setImage"
          style="display: none"
          ref="uploader"
        >
          <label for="fileInput" slot="upload-label">
            <figure></figure>
          </label>
          <span class="upload-caption">{{
            hasImage ? 'Replace' : 'Click to upload'
          }}</span>
        </image-uploader>
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="
            btn btn-light-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
          "
          @click="openUpload"
        >
          Anexar logotipo
        </button>
        <div class="preview" v-if="image">
          <img :src="image" style="width: 100%" />
        </div>
        <p
          class="text-muted font-weight-bold font-size-h4"
          style="margin-top: 20px"
        >
          <strong class="text-dark">Cor tema</strong> <br />
          Escolha pelo espectro de cor a ou insira a cor Hexadecimal no box
          abaixo. Você pode editar essas informações a qualquer momento pelo
          menu "Meu Perfil".
        </p>
        <ColorPicker
          :width="300"
          :height="300"
          :disabled="false"
          :startColor="color"
          @colorChange="onColorChange"
          style="margin: 0 auto"
        ></ColorPicker>
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          v-model="color"
          autocomplete="off"
          style="margin-top: 20px"
        />
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          class="
            btn btn-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
            mr-4
          "
          style="width: 150px"
          @click="step = 3"
        >
          CONTINUAR
        </button>
        <button
          type="button"
          class="
            btn btn-light-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
          "
          @click="step = 1"
        >
          VOLTAR
        </button>
      </div>
    </div>

    <!-- STEP THREE-->
    <div v-if="step === 3">
      <div class="pt-lg-0 pt-5">
        <p class="text-muted font-weight-bold font-size-h4">
          <strong class="text-dark">Cadastre seus atendentes</strong><br />
          Cadastre seus atendentes. Enviaremos um email para cada um deles, com
          instruções para concluir o cadastro. Você pode pular esta etapa e
          acessá-la mais tarde, através do menu.
        </p>
      </div>
      <SalesmenInvite @input="addUser" />
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          class="
            btn btn-success
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
            mr-4
          "
          style="width: 150px"
          @click="saveSteps"
        >
          AVANÇAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from 'vue-color-picker-wheel'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { UPDATE_STORE } from '@/core/services/store/auth.module'
import SalesmenInvite from '../../../components/Salesman/SalesmenInvite'

export default {
  name: 'RegisterAfterSteps',

  components: { ColorPicker, SalesmenInvite },

  props: {
    document: {
      type: String,
      default: ''
    }
  },

  directives: { mask },

  data: () => ({
    users: [],
    laboratory: [],
    hasImage: true,
    laboratories: [],
    image: null,
    step: 2,
    color: '#ff0000'
  }),

  computed: {
    ...mapGetters(['store'])
  },

  methods: {
    openUpload() {
      document.getElementById('fileInput').click()
    },

    alertUser() {
      if (
        window.confirm(
          'Deseja pular esta etapa? todas as modificações anteriores não serão salvas.'
        )
      ) {
        this.$router.push('/dashboard')
      }
    },

    fetchLabs() {
      let doc = '11.326.381/0001-10'
      if (this.document) {
        doc = this.document
      }
      axios.get(`/labs?document=${encodeURI(doc)}`).then(({ data }) => {
        this.laboratories = data.laboratories
        if (this.laboratories.length == 0) {
          this.step = 2
        }
      })
    },

    saveSteps() {
      const payload = {
        laboratories: this.laboratory,
        users: this.users,
        color: this.color,
        logo: this.image
      }

      this.$store
        .dispatch(UPDATE_STORE, { id: this.store.id, data: payload })
        .then(() => {
          this.$router.push('/dashboard')
        })
    },

    setImage(val) {
      this.image = val.dataUrl
    },

    addUser(user) {
      this.users.push(user)
    },

    onColorChange(color) {
      this.color = color
    }
  },

  mounted() {
    // this.fetchLabs()
  }
}
</script>

<style lang="css">
@import '~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';
.preview {
  min-height: 120px;
  border: 1px solid #ecf3f9;
}
.file-upload .input-wrapper .file-upload-label .file-upload-icon {
  display: inline-block;
  text-align: center;
  font-weight: 700;
  font-size: 28px;
  margin-top: 9px;
}
.file-upload .input-wrapper {
  text-align: center;
  position: relative;
  background-color: #aeaeb8 !important;
  height: 80px;
}
</style>
