<template>
  <div class="login-form login-signin">
    <RegisterAfterSteps v-if="steps" :document="form.document" />
    <form
      v-else
      class="form"
      novalidate="novalidate"
      id="kt_login_signup_form"
      @submit.stop.prevent="onSubmit"
    >
      <div class="pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Cadastre-se
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          Insira os detalhes da sua conta
        </p>
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="Nome Completo"
          v-model="form.name"
          name="name"
          ref="name"
          autocomplete="off"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="Celular"
          name="phone"
          v-model="form.phone"
          ref="phone"
          autocomplete="off"
          v-mask="['(##) ####-####', '(##) #####-####']"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="Whatsapp da ótica"
          name="whatsapp"
          v-model="form.whatsapp"
          ref="whatsapp"
          autocomplete="off"
          v-mask="['(##) ####-####', '(##) #####-####']"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="email"
          placeholder="Email"
          name="email"
          v-model="form.email"
          ref="email"
          autocomplete="off"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="Nome ótica"
          name="company_name"
          v-model="form.company_name"
          ref="company_name"
          autocomplete="off"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="CNPJ"
          name="document"
          v-model="form.document"
          ref="document"
          autocomplete="off"
          @blur="validarCNPJ"
          v-mask="['##.###.###/####-##']"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="password"
          placeholder="Password"
          name="password"
          v-model="form.password"
          ref="password"
          autocomplete="off"
        />
      </div>

      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="password"
          placeholder="Confirmar senha"
          v-model="form.password_confirmation"
          ref="password_confirmation"
          autocomplete="off"
        />
      </div>

      <div class="form-group">
        <Terms @accept="accepted" @accepted="accepted" />
      </div>

      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          :class="{
            'btn btn-primary': true,
            'font-weight-bolder font-size-h6': true,
            'px-8': true,
            'py-4': true,
            'my-3': true,
            'mr-4': true,
            disabled: !form.agree
          }"
          style="width: 150px"
          :disabled="!form.agree"
        >
          CADASTRAR
        </button>
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="
            btn btn-light-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
          "
          @click="$router.push('/login')"
        >
          CANCELAR
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import RegisterAfterSteps from './RegisterAfterSteps'
import { LOGOUT } from '@/core/services/store/auth.module'
import { REGISTER } from '@/core/services/store/auth.module'
import { mask } from 'vue-the-mask'
import Terms from '@/components/Auth/Terms'

export default {
  name: 'Register',

  components: { RegisterAfterSteps, Terms },

  directives: { mask },

  data: () => ({
    steps: false,
    terms: null,
    form: {
      name: null,
      phone: null,
      whatsapp: null,
      company_name: null,
      type: 'store',
      document: null,
      email: null,
      password: null,
      password_confirmation: null,
      agree: null
    }
  }),

  methods: {
    validarCNPJ() {
      let cnpj = this.form.document.replace(/[^\d]+/g, '')
      if (cnpj == '') return false
      if (cnpj.length != 14) return false

      const invalids = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999'
      ]

      if (invalids.includes(cnpj)) {
        alert('CNPJ Inválido')
        this.form.document = null
        return false
      }

      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho)
      let digitos = cnpj.substring(tamanho)
      let soma = 0
      let pos = tamanho - 7
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (resultado != digitos.charAt(0)) {
        alert('CNPJ Inválido')
        this.form.document = null
        return false
      }

      tamanho = tamanho + 1
      numeros = cnpj.substring(0, tamanho)
      soma = 0
      pos = tamanho - 7
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (resultado != digitos.charAt(1)) {
        alert('CNPJ Inválido')
        this.form.document = null
        return false
      }

      return true
    },

    resetForm() {
      this.form = {
        name: null,
        phone: null,
        company_name: null,
        document: null,
        email: null,
        password: null,
        type: 'store',
        password_confirmation: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },

    accepted(agree) {
      this.form.agree = agree
    },

    onSubmit() {
      if (!this.validarCNPJ()) {
        return false
      }

      this.$store.dispatch(LOGOUT)

      const submitButton = this.$refs['kt_login_signup_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      this.$store.dispatch(REGISTER, this.form).then(() => {
        this.steps = true
      })

      submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
    }
  },

  created() {
    let url = window.location.href
    if (url.includes('steps=1')) {
      this.steps = true
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
